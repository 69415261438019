<template>
  <div>
    <div>
      <h1>About {{tenant.name}}</h1>
      <p>
        Our goal is to encourage everyone to live an active and healthy lifestyle. 
        Studies show having healthy employees improves productivity and radically reduces healthcare costs.
      </p>

      <div class="blob-bg orange white--text text-center pa-16 mb-8"> 
        <h2 class="subtitle">Corporate Fitness helps to</h2>
          <p>1) Improve overall health and happiness</p>
          <p>2) Increases productivity</p>
          <p>3) Reduce stress</p>
          <p>4) Reduce healthcare costs</p>
      </div>

      <h2 class="subtitle">Who we are</h2>
      <p>
        We were the inventors of the first Dutch running app and platform more than 10 years ago.
      </p>
      <p>
        {{tenant.name}} is powered by the <a href="https://sodisp.com">sodisp.com Challenge Platform</a> and is proudly produced and maintained by Noctilucent BV from The Netherlands.
      </p>
      <p>
        We'd love to have your feedback on our website, so feel free to drop us a line.
      </p>

      <p>
        <v-btn color="primary" to="/contact">Contact Us</v-btn>
      </p>
    </div>

  </div>
</template>
<script>
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "About",
  props: {
  },
  data: function() {
    return {
      tenant: tenant,
    };
  },
  created() {
  },
  mounted() {
  },
  methods: {
  },
  watch: {
  }
};
</script>

<style lang="scss" scoped>
  .blob-bg {  font-size: 200%; text-align: center;}

  
@media(max-width:576px){
    .blob-bg { font-size: 125%; padding: 20px !important;}
}
</style>